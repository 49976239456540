
const SpinnerComponent = () => {
  return (
    <div className="fallback-spinner vh-90" style={{top:"10vh",marginTop:"26rem"}}>
      <div className="loading">
        <div className="loader-inner line-spin-fade-loader">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  )
}

export default SpinnerComponent
